<template>
  <v-rating
      color="#f16816"
      :size="size"
      dense
      readonly
      half-increments
      background-color="#f16816"
      :value=rate/2
  ></v-rating>
</template>

<script>
export default {
  name: "StarRating",
  props: {
    rate: Number,
    size: {
      type: String,
      default: '20'
    }
  }
}
</script>

<style scoped>

</style>