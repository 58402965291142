<template>
  <div>
    <v-row>
      <v-col cols="12" md="6" lg="6" xl="6">
        <v-row>
          <v-col>
            <div class="text-h4">Sumário</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p class="text-justify text-body-1" v-for="(paragraph, index) of brokerSummary" v-bind:key="index" >{{ paragraph }}</p>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6" lg="6" xl="6">
        <v-row>
          <v-col>
            <div class="text-h4">Avaliações</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <list :broker-info="brokerRating"></list>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-row>
          <v-col cols="12" md="6" lg="6" xl="6">
            <v-row>
              <v-col>
                <div class="text-h5">Informações da Conta</div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <list :broker-info="brokerAccountInfo"></list>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" lg="6" xl="6">
            <v-row>
              <v-col>
                <div class="text-h5">Condições de Negociação</div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <list :broker-info="brokerNegotiationConditions"></list>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="12" lg="12" xl="12">
            <v-row>
              <v-col>
                <div class="text-h5">Detalhes da Empresa</div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <list :broker-info="brokerDetails"></list>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import List from "@/components/broker/ui/List";

export default {
  name:"BrokerInformation",
  components: {List},
  props: {
    brokerSummary: Array,
    brokerRating: Object,
    brokerAccountInfo: Object,
    brokerNegotiationConditions: Object,
    brokerDetails: Object
  }
}

</script>

<style scoped>

</style>