<template>
  <div>
    <v-row>
      <v-col>
        <template v-for="(section, sIndex) of sections">
          <div :key="sIndex">
            <template v-if="section.title.name">
              <v-row>
                <v-col>
                  <h1 :id="'section'+sIndex" :class="section.title.parent ? 'text-h4' : 'text-h5'">{{ section.title.name }}</h1>
                </v-col>
              </v-row>
            </template>
            <v-row>
              <v-col>
                <template v-for="(paragraph, pIndex) of section.paragraphs">
                  <div :key="pIndex">
                    <template v-if="typeof(paragraph) == 'string'">
                      <p class="text-justify text-body-1" :key="sIndex+pIndex" >{{ paragraph }}</p>
                    </template>
                    <template v-else-if="paragraph.type==='table'">
                      <v-data-table
                          style="margin-bottom: 16px"
                          :headers="paragraph.headers"
                          :items="paragraph.items"
                          hide-default-footer
                          disable-sort
                      ></v-data-table>
                    </template>
                    <template v-else-if="paragraph.type==='list'">
                      <li class="text-body-1 text-justify" v-for="(item, iIndex) of paragraph.values" :key="iIndex">
                        {{ item }}
                      </li>
                    </template>
                    <template v-else>
                      <v-row justify="center">
                        <template v-if="typeof(paragraph.src) == 'object'">
                          <v-col align-self="center" :key="sIndex"
                                 :cols="paragraph.portrait ?  6 : 12"
                                 :md="paragraph.portrait ? 3 : 5"
                                 :lg="paragraph.portrait ? 3 : 5"
                                 :xl="paragraph.portrait ? 2 : 3"
                                 v-for="(src, sIndex) of paragraph.src" >
                            <div class="d-flex justify-center">
                            <pinch-zoom :wheel="false" :zoomControlScale="2">
                              <v-sheet>
                                <v-img
                                    contain
                                    :src="src"
                                ></v-img>
                              </v-sheet>
                            </pinch-zoom>
                            </div>
                          </v-col>
                        </template>
                        <template v-else>
                          <v-col align-self="center">
                            <div class="d-flex justify-center">
                              <pinch-zoom :wheel="false" :zoomControlScale="2">
                                <v-img
                                    contain
                                    :src="paragraph.src"
                                ></v-img>
                              </pinch-zoom>
                            </div>
                          </v-col>
                        </template>
                      </v-row>
                    </template>
                  </div>
                </template>
              </v-col>
            </v-row>
          </div>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name:"Sections",
  props:{
    sections: Array,
  }
}
</script>

<style>
.pz-zoom-button{
  z-index: unset !important;
}
</style>