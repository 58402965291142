import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                background: colors.shades.white,
                back: colors.shades.white,
                primary: colors.shades.black,
                secondary: colors.grey.darken1,
                tertiary: '#f16816',
                accent: colors.shades.black,
                error: colors.red.accent3,
            },
            dark: {
                background: '#121212',
                back: colors.grey.darken4,
                primary: colors.shades.white,
                secondary: colors.grey.darken1,
                tertiary: '#f16816',
                accent: colors.shades.white,
                error: colors.red.accent3,
            },
        },
    },
});
