<template>
  <div>
    <v-card class="mb-10 elevation-0 card-title" style="border-radius: 0;">
      <div class="background fill-height">
        <v-row class="text" justify="center" :class="{'text-center':$vuetify.breakpoint.xs}" style="height: 100%;">
        <v-col class="px-1" align-self="center" cols="11" lg="8">
          <h1 class="white--text text-h4 font-weight-bold">
            <slot>-</slot>
          </h1>
        </v-col>
      </v-row>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "TitleCard",
}
</script>

<style scoped>
.card-title{
  height: 300px;
}

.background{
  background-image: url('../../../src/assets/images/brokers.png');
  background-position: center center;
  background-size: cover;
  opacity: 1;
  background-color: black;
}
.text{
  background-color: rgba(0,0,0, 0.6); /* Black w/opacity/see-through */
}
</style>