<template>
  <div>
    <template v-for="(broker, index) of Object.values(brokers)" >
      <broker-list-block :rank="index+1" :broker="broker" v-bind:key="broker.label+'l'"/>
      <v-divider class="mt-3 mb-3" v-if="Object.keys(brokers).length !== index + 1" v-bind:key="broker.label+'d'"></v-divider>
    </template>
    
  </div>
</template>

<script>
import brokers from "../../assets/data/brokers.json";
import BrokerListBlock from "./BrokerListBlock";
export default {
  name: "BrokerList",
  components: {BrokerListBlock},
  data(){
    return {
      brokers:{}
    }
  },
  mounted() {
    this.brokers = Object.values(brokers)
    this.brokers.sort((a,b) => (a.rate > b.rate) ? -1 : ((b.rate > a.rate) ? 1 : 0))
  }
}
</script>

<style scoped>

</style>