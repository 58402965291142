<template>
  <div>
    <v-btn
        :to="path"
        @click=" $emit('closePanel')"
        class="text--secondary text-decoration-none text-capitalize"
        text
        :autocapitalize="false"
    >
      <slot></slot>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "NavBarListItem",
  props: {
    path: String
  }
}
</script>

<style scoped>

</style>