<template>
  <div style="position: relative; height:23em;">
    <canvas :id="brokerLabel"></canvas>
  </div>
</template>

<script>

import Chart from "chart.js/auto";

export default {
  name: "BrokerChart",
  props: {
    brokerRating: Object,
    brokerLabel: String,
  },
  data() {
    return{
      chart: {},
      backgroundColor: {
        darkTheme: ['rgba(240, 167, 160, 0.9)','rgba(35, 87, 137, 0.9)','rgba(193, 41, 46, 0.9)','rgba(241, 211, 2, 0.9)', 'rgba(0, 145, 110, 0.9)', 'rgb(117, 221, 221, 0.9)', 'rgba(6, 190, 225, 0.9)'],
        lightTheme: ['rgba(240, 167, 160, 0.6)','rgba(35, 87, 137, 0.6)','rgba(193, 41, 46, 0.6)','rgba(241, 211, 2, 0.6)', 'rgba(0, 145, 110, 0.6)', 'rgba(117, 221, 221, 0.6)', 'rgba(6, 190, 225, 0.6)']
      }
    }
  },
  methods: {
    updateChartTheme(chart) {
      chart.data.datasets[0].backgroundColor = this.backgroundColor.darkTheme
      chart.options.scales.r.grid.color = 'white'
      chart.options.plugins.legend.labels.color = 'white'
      chart.options.scales.r.angleLines.color = 'white'
      chart.update()
    },
    resetChartTheme(chart) {
      chart.data.datasets[0].backgroundColor = this.backgroundColor.lightTheme
      chart.options.plugins.legend.labels.color = 'rgba(89,89,89,1)'
      chart.options.scales.r.grid.color = 'rgba(217,217,217,1)'
      chart.options.scales.r.angleLines.color = 'rgba(217,217,217,1)'
      chart.update()
    }
  },
  watch: {
    '$vuetify.theme.dark'(newValue){
      if (newValue){
        this.updateChartTheme(this.chart)
      }
      else{
        this.resetChartTheme(this.chart)
      }
    }
  },
  mounted() {
    let ratingLabels = []
    let ratingValues = []
    for (const rating in this.brokerRating) {
      if(this.brokerRating[rating].type==='star') {
        ratingLabels.push(this.brokerRating[rating].label)
        ratingValues.push(this.brokerRating[rating].value)
      }
    }
    const ctx = document.getElementById(this.brokerLabel).getContext('2d');
    this.chart = new Chart(ctx, {
      type: 'polarArea',
      data: {
        labels: ratingLabels,
        datasets: [{
          data: ratingValues,
          backgroundColor: this.$vuetify.theme.dark ? this.backgroundColor.darkTheme : this.backgroundColor.lightTheme,
          borderColor: 'white',
          borderWidth: 1,
        }]
      },
      options: {
        maintainAspectRatio: false,
        interactions:{
          legend:{
            display: false
          }
        },
        plugins:{
          legend:{
            labels: {
              font: {
                family: 'Roboto',
                weight: 500
              },
              color: this.$vuetify.theme.dark ? 'white' : 'rgba(89,89,89,1)',
            },
          },
          tooltip:{
            backgroundColor: 'rgba(124,100,58,0.9)',
            displayColors: false,
            bodyFont: {
              family: 'Roboto',
              size: '14px',
            }
          }
        },
        scales: {
          r: {
            min: 0,
            max: 5,
            grid: {
              color: this.$vuetify.theme.dark ? 'white' : 'rgba(217,217,217,1)',
              circular: true,
              drawTicks: false,
            },
            angleLines: {
              color: this.$vuetify.theme.dark ? 'white' : 'rgba(217,217,217,1)',
            },
            ticks: {
              display: false
            }
          },
        }
      }
    })
  }
}

</script>

<style scoped>

</style>