<template>
  <div>
    <v-row justify="center">
      <v-col class="text-center" cols="12" sm="8" md="5" lg="6" xl="5">
        <v-row>
          <v-col>
            <div class="text-h5">Métodos de Depósito e Retirada</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-row justify="center">
              <v-col cols="auto" v-for="(funding, index) of brokerFundings" :key="index">
                <v-card light outlined>
                  <v-img
                      :src="`/funding-avatars/${funding}.webp`"
                      height="60"
                      width="80"
                      contain
                  ></v-img>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-divider v-if="!$vuetify.breakpoint.xs" vertical inset ></v-divider>
      <v-col class="text-center" cols="12" sm="8" md="5" lg="6" xl="5">
        <v-row>
          <v-col>
            <div class="text-h5">Plataformas Suportadas</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-row justify="center">
              <v-col cols="auto" v-for="(platforms, index) of brokerSupportedPlatforms" :key="index">
                <v-card light outlined>
                  <v-img
                      :src="`/platform-avatars/${platforms}.webp`"
                      height="60"
                      width="80"
                      contain
                  ></v-img>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
  export default {
    name:"BrokerMethods",
    props:{
      brokerFundings: Array,
      brokerName: String,
      brokerSupportedPlatforms: Array
    }
  }

</script>

<style scoped></style>