import Vue from 'vue'
import VueRouter from "vue-router";
import FlagIcon from 'vue-flag-icon'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import PinchZoom from 'vue-pinch-zoom';
import VueHead from 'vue-head';

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(VueHead, {
  separator: '-',
  complement: 'Melhores Corretoras de Forex'
})
Vue.use(FlagIcon)
Vue.component('pinch-zoom', PinchZoom);

const router = new VueRouter({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x:0, y:0, behavior:'smooth'
      }
    }
  },
  routes: [
    {path:'/aboutus', name:'aboutus'},
    {path:'/ratingmethod', name:'ratingmethod'},
    {path:'/forexaccount', name:'forexaccount'},
    {path:'/forextrading', name:'forextrading'},
    {path:'/forexbroker', name:'forexbroker'},
    {path:'/broker/:label', name:'broker'},
    {path:'/forexguide', name:'forexguide'},
    {path:'/',  name:'home'},
  ]
})

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
