<template>
  <div>
    <div class="px-5">
      <v-row justify="center" class="pb-6">
        <v-col cols="12" lg="8">
          <broker-panel
            :broker-label="broker.label"
            :broker-main-info="broker.mainInfo"
            :broker-rate="broker.rate"
            :broker-rank="broker.rank"
            :broker-link="broker.link"
          ></broker-panel>
        </v-col>
      </v-row>
    </div>
    <div class="px-5">
      <v-row justify="center">
        <v-col cols="12" lg="8">
          <v-row>
            <v-col>
              <broker-information
                  :broker-summary="broker.summary"
                  :broker-account-info="broker.accountInfo"
                  :broker-details="broker.details"
                  :broker-negotiation-conditions="broker.negotiationConditions"
                  :broker-rating="broker.rating"
              ></broker-information>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div class="px-5">
      <v-row class="pt-12 pb-5">
        <v-col>
          <v-row justify="center">
            <v-col cols="12" lg="8">
              <broker-methods
                  :broker-fundings="broker.fundings"
                  :broker-name="broker.name"
                  :broker-supported-platforms="broker.supportedPlatforms"
              ></broker-methods>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div class="px-5">
      <v-row justify="center">
        <v-col cols="12" lg="8">
          <broker-points
              :broker-pros="broker.pros"
              :broker-cons="broker.cons"
          ></broker-points>
          <sections
              :sections="broker.sections"
          ></sections>
        
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import brokers from "../assets/data/brokers.json";
import BrokerMethods from "@/components/broker/Methods";
import BrokerPoints from "@/components/broker/Points";
import BrokerInformation from "@/components/broker/Information";
import BrokerPanel from "@/components/broker/Panel";
import Sections from "@/components/ui/Sections";


export default {
  name: "Broker",
  components: {Sections, BrokerPanel, BrokerInformation, BrokerPoints, BrokerMethods},
  beforeMount() {
    if(!brokers[this.$route.params.label].evaluationAvaliable)
    {
      this.$router.push("/")
    }
  },
  data(){
    return {
      broker:brokers[this.$route.params.label]
    }
  },
  head: {
    title: function () {
      return {
        inner: this.broker.name
      }
    }
  }
}
</script>

<style scoped>

</style>