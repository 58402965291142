<template>
  <v-fab-transition >
    <v-btn class="white--text"
           v-ripple
           color="tertiary"
           fab
           elevation="0"
           large
           fixed
           bottom
           right
           @click="$vuetify.goTo(0)"
    >
      <v-icon>arrow_upward</v-icon>
    </v-btn>
  </v-fab-transition>
</template>

<script>
export default {
  name: "GoToTopBtn"
}
</script>

<style scoped>

</style>