<template>
  <div>
    <v-row class="pb-10" justify="center">
      <v-col :class="{'text-center':$vuetify.breakpoint.xs}" class="text-right" cols="12" sm="6" md="6" lg="6" xl="6">
        <v-row class="py-5">
          <v-col>
            <v-row dense no-gutters>
              <v-col>
                <div class="text-h5 font-weight-bold">
                  Prós
                  <v-icon color="green">mdi-thumb-up</v-icon>
                </div>
              </v-col>
            </v-row>
            <v-row class="pt-2" no-gutters>
              <v-col align-self="center">
                <template v-for="(pro, index) of brokerPros" >
                  <div :key="index">
                    <div class="text-body-1 my-1" >{{ pro }}</div>
                    <div class="justify-end d-flex" :class="{'justify-center':$vuetify.breakpoint.xs}">
                      <v-divider style="max-width: 80%" v-if="index+1<brokerPros.length"></v-divider>
                    </div>
                  </div>
                </template>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-divider v-if="!$vuetify.breakpoint.xs" vertical inset></v-divider>
      <v-col :class="{'text-center':$vuetify.breakpoint.xs}" class="text-left" cols="12" sm="6" md="6" lg="6" xl="6">
        <v-row class="py-5">
          <v-col>
            <v-row dense no-gutters>
              <v-col>
                <div class="text-h5 font-weight-bold">
                  <v-icon color="red">mdi-thumb-down</v-icon>
                  Contras
                </div>
              </v-col>
            </v-row>
            <v-row class="pt-2" no-gutters>
              <v-col align-self="center">
                <template v-for="(con, index) of brokerCons" >
                  <div :key="index">
                    <div class="text-body-1 my-1" >{{ con }}</div>
                    <div class="d-flex" :class="{'justify-center':$vuetify.breakpoint.xs}">
                      <v-divider style="max-width: 80%" v-if="index+1<brokerCons.length"></v-divider>
                    </div>
                  </div>
                </template>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name:"BrokerPoints",
    props:{
      brokerPros: Array,
      brokerCons: Array
    }
  }
</script>

<style scoped></style>