<template>
  <div>
    <template v-for="broker of Object.values(brokers)">
      <div :key="broker.label">
        <v-row>
          <v-col>
            <h2 class="text-h4">{{ broker.name }}</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p v-for="(paragraph, index) of broker.rankingSummary"
               class="text-justify text-body-1"
               v-bind:key="index"
            >{{ paragraph }}</p>
          </v-col>
        </v-row>
        <v-row no-gutters justify="center">
          <v-col cols="12" sm="10" md="6" lg="6" xl="5">
            <broker-chart
                :broker-label="broker.label"
                :broker-rating="broker.rating"
            ></broker-chart>
          </v-col>
          <v-col align-self="center" cols="12" md="6" lg="6" xl="7">
            <broker-points
                :broker-pros="broker.pros"
                :broker-cons="broker.cons"
            ></broker-points>
          </v-col>
        </v-row>
        <v-row :justify="$vuetify.breakpoint.xs ? 'center' : 'start'">
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="auto">
            <v-btn color="tertiary" class="pa-6 white--text" @click="clickOpenAcc(broker.link)">
              Abra sua conta
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn :disabled="!broker.evaluationAvaliable" color="tertiary" :to="'broker/' + broker.label" outlined class="pa-6 white--text">
              Leia nossa avaliação
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </div>
    </template>
  </div>
</template>

<script>
import brokers from "../../assets/data/brokers.json";
import BrokerChart from "@/components/brokers/BrokerChart";
import BrokerPoints from "@/components/broker/Points";

export default {
  name: "BrokerOverview",
  components: {BrokerPoints, BrokerChart },
  data(){
    return {
      brokers:{}
    }
  },
  methods: {
    clickOpenAcc(link){
      window.open(link, '_blank');
    },
  },
  mounted() {
    this.brokers = Object.values(brokers)
    this.brokers.sort((a,b) => (a.rate > b.rate) ? -1 : ((b.rate > a.rate) ? 1 : 0))
  }
}
</script>

<style scoped>

</style>