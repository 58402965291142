<template>
  <div>
    <div class="px-5">
      <v-row  justify="center">
        <v-col cols="12" lg="8">
          <sections
            :sections="sections"
          ></sections>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" lg="8">
          <h2 class="text-h4">Melhores Corretoras de Forex</h2>
        </v-col>
        <v-col cols="12" lg="8">
          <broker-list></broker-list>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>

import Sections from "@/components/ui/Sections";
import BrokerList from "@/components/brokers/BrokerList";

export default {
  name: "ForexGuide",
  components: {BrokerList, Sections},
  head: {
    title: function () {
      return {
        inner: 'Guia'
      }
    }
  },
  data: () => ({
    sections: [
      {
        title: {
          name: 'O que é Forex Trading?',
          parent: true
        },
        paragraphs: [
          'A negociação de Forex é a troca de moedas para obter lucro com as flutuações na taxa de câmbio. Para abrir uma negociação, um trader deve escolher um par de moedas e a direção em que espera que a taxa de câmbio se mova. À medida que a taxa de câmbio entre as duas moedas muda, o trader pode fechar a negociação para obter lucro ou perda.',
          'Pares de moedas são uma expressão do valor de uma moeda em termos de outra moeda. Por exemplo, se o USD/BRL estiver em 1/4, estamos dizendo que 1 dólar americano é igual a 4 reais.',
          'No Forex trading, os pares de moedas comuns incluem GBP/USD, AUD/USD e EUR/USD. Se você comprar o GBP/USD estará efetivamente comprando o GBP e vendendo o dólar ao mesmo tempo. Quando você fecha sua posição, está vendendo a libra esterlina que comprou e comprando de volta o dólar. Se a libra esterlina se fortalecer em relação ao dólar nesse período, você terá lucro — já que agora poderá pegar mais dólares do que vendeu originalmente.'
        ]
      },
      {
        title: {
          name: 'Como você negocia Forex?',
          parent: false
        },
        paragraphs: [
          'Para começar a negociar, um participante terá acesso a um mercado em que pares de moedas podem ser comprados e vendidos. Uma corretora Forex é a única maneira de acessar esse mercado e, dependendo de como a corretora está configurada, a corretora manterá o mercado (criador de mercado) ou oferecerá uma conexão direta com o mercado internacional (acesso direto ao mercado). De qualquer forma, o participante precisará criar uma conta de negociação em uma corretora e configurar uma plataforma de negociação para começar.',
          'O Forex trading é geralmente executado por meio de um produto chamado Contrato por Diferença (CFD) com a sua corretora. Este é um contrato entre você e sua corretora para pagar qualquer diferença no preço das duas moedas entre a abertura e o fechamento da sua transação. Isso é muito útil, pois significa que nem você nem sua corretora precisam ficar de posse de qualquer moeda.'
        ]
      },
      {
        title: {
          name: 'Qual é a diferença entre negociar Forex e negociar ações?',
          parent: false
        },
        paragraphs: [
          'Quando pensamos em investir, muitas vezes ouvimos sobre a negociação de ações no mercado de ações e, erroneamente, incluímos o comércio de Forex no mesmo grupo. Forex trading é uma forma de negociação de CFDs e difere de investir em ações de várias maneiras.',
          'As moedas são negociadas em pares, enquanto as ações são físicas e compradas com dinheiro. Trocar pares de moedas significa que, à medida que você compra um par, você está vendendo o outro ao mesmo tempo. Nessa compra e venda simultânea de moedas, é o valor relativo entre as duas moedas do par que gera o lucro.',
          'O mercado Forex é uma troca descentralizada de balcão, onde todas as transações e participantes são confidenciais — ao contrário do mercado de ações, que são centralizados e os registros públicos de compradores e vendedores são mantidos.',
          'Um elemento atraente da troca de moeda é o baixo custo de entrada. Para obter lucros substanciais, os corretores de ações usam grandes quantidades de capital, o que, obviamente, não é uma opção para investidores com renda limitada.',
          'Forex trading não é um investimento. Qualquer transação no mercado Forex ou CFD não dá ao trader a propriedade parcial do ativo que está sendo transacionado. Nesse caso, o trader está especulando sobre o valor futuro dos ativos envolvidos no negócio. Assim, chamá-lo de investimento seria incorreto, pois os participantes desse mercado estão apenas especulando sobre o valor dos ativos.',
          'Pares de moedas são produtos alavancados. A alavancagem permite que o trader faça negócios muito maiores do que o que seu saldo de conta permite, emprestando fundos adicionais através da corretora. Alavancagem significa que todos os lucros são ampliados, assim como quaisquer perdas. Os negociantes são responsáveis pelas perdas do valor total negociado — como tal, o uso de altos montantes de alavancagem pode levar a perdas significativas.'
        ]
      },
      {
        title: {
          name: 'O trading Forex é adequado para mim?',
          parent: false
        },
        paragraphs: [
          'Até agora você deve saber que é de alto risco, que você precisa encontrar uma corretora com a qual se sinta confortável, e definir o valor que você deseja colocar em sua conta na corretora. Para negociar Forex você deve assumir o compromisso de aprender, e por isso deve estar pronto para:',
          {
            "type": "list",
            "values": [
              "Comparar as melhores corretoras do Brasil para encontrar uma que combine com você.",
              "Ler nossa seção educacional e aprender tudo o que puder.",
              "Compreender a forma como o mercado Forex e o comércio de CFDs funcionam.",
              "Aprender o software e as ferramentas que irão alimentar sua negociação.",
              "Estar preparado para perder todo o dinheiro que você colocar em uma conta. Não deposite dinheiro que não possa perder."
            ]
          }
        ]
      },
    ]
  })
}
</script>

<style scoped>

</style>