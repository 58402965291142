<template>
  <div>
    <essay
      :sections="sections"
    ></essay>
  </div>
</template>

<script>
import Essay from "@/components/layout/Essay";

export default {
  name: "Trading",
  components: {Essay},
  head: {
    title: function () {
      return {
        inner: 'Forex Trading'
      }
    }
  },
  data : () => ({
    sections: [
      {
        title: {
          name:'',
          parent: false
        },
        paragraphs: [
            'Negociar Forex é basicamente trocar moedas em um mercado aberto e internacional. Negociantes (ou Traders) de Forex especulam sobre os valores das moedas, tentando prever se vai aumentar ou diminuir ao longo do tempo, em relação a uma segunda moeda. Para atuar nesse mercado, os investidores primeiro escolhem o montante que querem negociar, o par de moedas em que desejam investir e o prazo aproximado que pretendem manter a operação aberta.',
            'Essa solicitação é repassada ao mercado por um terceiro, frequentemente conhecido como corretora (ou Broker). O mercado Forex é um mercado aberto e o maior mercado financeiro do mundo, medido em volume de negociação. Uma instituição financeira com as credenciais corretas poderá abrir e fechar posições em nome de clientes, mas há muitos participantes diferentes que atuam na negociação de moedas.',
            'Enquanto o mercado Forex se move em incrementos muito pequenos, as notícias econômicas, como aumentos de impostos ou das taxas de juros, podem afetar ainda mais os valores das moedas. Os negociantes de Forex só podem fazer dinheiro em um mercado que tem essa volatilidade, então sempre devem ficar à frente das principais notícias para obter lucro.',
            'As corretoras de Forex que representam os negociantes compõem uma quantia muito pequena do dinheiro que é representado no mercado – a maior quantia está sendo investida pelos bancos. Os bancos estão continuamente investindo e criando a sua própria volatilidade nos mercados, e é com toda essa volatilidade que os pequenos negociantes de Forex tentam lucrar.'
        ]
      },
      {
        title: {
          name:'Como Aprender A Negociar Forex',
          parent: true
        },
        paragraphs: [
          'Não há uma maneira única de iniciar seus investimentos em Forex. Existem dois tipos principais de traders, e cada um deles pode ter interesse em negociar a partir de duas direções diferentes. Há um conjunto de investidores que realmente apreciam as notícias e entendem como elas afetam a economia global e os valores das moedas. Esses leitores de notícias ganham vantagem na Análise Fundamental e podem ser considerados “cambistas” muito eficientes.',
          'O outro tipo de investidor é o matemático, que tem interesse em gráficos e números. Os negociantes técnicos operam quase inteiramente com base em dados e tendências de mercado, e realmente apreciam as estratégias profundamente complexas e o foco necessário para executá-las. Essas estratégias de negociação não são mutuamente exclusivas, então cada negociante precisa entender um pouco sobre as negociações fundamentais e técnicas. Mas voltaremos a este assunto depois.'
        ]
      },
      {
        title: {
          name:'Negociar Forex É Rentável?',
          parent: true
        },
        paragraphs: [
            'Mudanças nos valores das moedas no mercado Forex são muito pequenas em comparação com outros mercados financeiros, como a bolsa de valores. Assim, a menos que você seja um banco que esteja investindo milhões de dólares em uma única operação, você não verá nenhum lucro significativo que faça a negociação valer o tempo e o risco. É por isso que os pequenos investidores usam algo chamado alavancagem.',
            'A alavancagem é usada como uma maneira de ampliar os lucros nas negociações. Isso significa que um provedor de liquidez, geralmente um terceiro, emprestará dinheiro adicional para que você possa manter uma posição maior. Ao usar alavancagem, você deve ser capaz de cobrir quaisquer perdas na sua conta de negociação, considerando a quantidade de alavancagem que você está usando. Portanto, alavancagem também aumenta a sua exposição ao risco.',
            'Mas ter alavancagem disponível pode tornar as negociações de Forex extremamente lucrativas, desde que você ganhe mais operações do que perca. Há muito poucos negociantes que são bons o suficiente para poderem deixar o seu trabalho e atuarem full time nesse mercado, mas há pessoas que ganham dinheiro extra para pagar as férias todos os anos, por exemplo.'
        ]
      },
      {
        title: {
          name:'Risco Em Negociar Forex',
          parent: true
        },
        paragraphs: [
            'A natureza dos mercados financeiros é que eles são voláteis e podem se mover sem aviso prévio. Eles podem ir em qualquer direção, e podem especialmente seguir em uma direção que você não tinha antecipado. Há muitos fatores que afetam os mercados, e há grandes bancos que têm capital para mover os mercados e realizar lucro. Se você está pensando em negociar Forex, deve saber que negociar em qualquer produto similar a CFDs que seja de natureza especulativa expõe você a um grande risco.',
            'Como regra geral, você nunca deve negociar com dinheiro que não esteja disposto a perder. E como segunda regra, você deve estar pronto a perder algumas operações. Você só tem que manter uma relação de ganhos e perdas saudável e nunca deve colocar mais de 2% do seu capital em uma única operação. Nas negociações em Forex você pode atenuar sua exposição ao risco, mas nunca pode fugir dele. Espere perdas. Espere ganhos. E espere ser surpreendido.'
        ]
      },
      {
        title: {
          name:'Tendências Do Mercado (Touro Vs Urso)',
          parent: true
        },
        paragraphs: [
            'Um dos principais princípios de negociar Forex é especular com a tendência do mercado. Se o mercado está indo em uma certa direção, é justo supor que continuará se movendo nessa direção até que algo aconteça. Existem duas tendências principais sobre as quais você ouvirá falar no mercado – a tendência de baixa (Urso) e a tendência de alta (Touro).',
        ]
      },
      {
        title: {
          name:'Tendência de Baixa',
          parent: false
        },
        paragraphs: [
            'Uma tendência de Urso é uma tendência de queda predominante. Isso significa que a tendência geral vai ser para baixo, independentemente de qualquer movimento ascendente em relação à tendência geral. Abaixo está um exemplo dessa tendência de baixa.'
        ]
      },
      {
        title: {
          name:'Tendência de Alta',
          parent: false
        },
        paragraphs: [
            'Uma tendência de Touro acontece quando a tendência predominante é de alta, independentemente de movimentos menores que vão contra a tendência geral de crescimento.'
        ]
      },
      {
        title: {
          name:'Long & Short',
          parent: true
        },
        paragraphs: [
            'Quando os negociantes de Forex especulam sobre moedas e abrem uma posição, eles o fazem de forma a indicar de que maneira acreditam que o mercado irá seguir. Uma operação pode ser lucrativa para um negociante tanto em um mercado de alta quanto de baixa, desde que ele indique se está indo Long ou Short ao executar a operação. Ir Long é quando o negociante acredita que o par de moedas irá aumentar de valor.',
            'Ele acredita que o mercado está otimista. Nesse caso, está comprando a primeira moeda do par. Ir Short é diferente. É uma aposta contra o mercado, na crença de que o valor do par de moedas vai desvalorizar. Nesse caso, o negociante está comprando a segunda moeda do par e, se ela aumentar de valor enquanto a primeira moeda cai, ele lucra.'
        ]
      },
      {
        title: {
          name:'Pares De Moedas De Forex',
          parent: true
        },
        paragraphs: [
            'As moedas são sempre negociadas em pares. Isso ocorre porque o valor de uma moeda só pode aumentar ou diminuir em relação a outra. E quando negociamos Forex, estamos comprando ou vendendo uma das moedas do par. Para o caso de simplicidade e padronização, todas as moedas têm um código de 3 letras para representá-las. As duas primeiras letras são compostas pelo país e a terceira é a moeda. Por exemplo, o USD é o Dólar dos Estados Unidos e o Real do Brasil é o BRL.   Abaixo está um exemplo de um par que inclui o Dólar dos Estados Unidos e o Real do Brasil. É assim que você vai ver na sua plataforma de negociação.',
            'Pares de moedas são agrupados em três categorias. Elas são conhecidas como Maiores, Menores e Exóticas. Para saber mais sobre quais pares de moedas você deve negociar e quais evitar, leia o nosso artigo aqui.  '
        ]
      },
      {
        title: {
          name:'Os Pares Maiores',
          parent: false
        },
        paragraphs: [
            'É um grupo de 7 pares de moedas que têm uma combinação entre o USD e outra moeda importante. Eles são:'
        ]
      },
      {
        title: {
          name:'Os Pares Menores',
          parent: false
        },
        paragraphs: [
            'Estes são o grupo de moedas que não contêm o dólar, mas sim duas moedas de economias fortes. Alguns exemplos:'
        ]
      },
      {
        title: {
          name:'Os Pares Exóticos',
          parent: false
        },
        paragraphs: [
            'São pares compostos de uma moeda de uma economia desenvolvida e outra de uma economia emergente. Esses pares não são frequentemente negociados, então espere falta de liquidez, assim como spreads muito altos. Alguns exemplos são:'
        ]
      },
      {
        title: {
          name:'Pips & Spread',
          parent: true
        },
        paragraphs: [
            'A menor alteração que pode ocorrer no valor de uma moeda é chamada de PIP. No exemplo abaixo, temos o par USD/EUR que possui um valor de 0,8106. Um único pip é o movimento da quarta casa decimal. Caso o valor do par aumente para 0.8107, seria um aumento de 1 pip, e se ele cair para 0.8105, seria um decréscimo no valor de 1 pip. Os números em vermelho, ao lado do valor da moeda, representam a mudança diária. Podemos ver que até agora o par USD/EUR caiu 15 PIPs. '
        ]
      },
      {
        title: {
          name:'Lotes E Tamanho Da Porção',
          parent: true
        },
        paragraphs: [
            'Quando você abre uma operação, você não precisa apenas escolher um par de moedas, mas também o tamanho da porção, medido em lotes. Um lote Padrão é a maior medida do tamanho da porção e pode ser dividido em partes menores. Eles são:'
        ]
      },
      {
        title: {
          name:'Análises Na Negociação De Forex',
          parent: true
        },
        paragraphs: [
            'Nós dissemos anteriormente que cada negociante é diferente. Alguns preferem negociar a partir de notícias e outros a partir de indicadores de observação nos dados financeiros. Seja qual for seu estilo, todos os investidores precisam fazer análises para encontrar oportunidades futuras de negociar.  Ao entrar neste mercado, você precisará descobrir o tipo de trader que você é e escolher o tipo de análise que prefere. A seguir estão alguns exemplos do que encontrar em cada tipo de análise.',
            'Análise Fundamental:  Os negociantes que estão fazendo Análise Fundamental estão à procura de notícias que tenham impacto no valor de qualquer uma das moedas em um par de moedas. Exemplo: Durante o dia, o governo dos Estados Unidos lançará um relatório chamado Índice de Preços da Habitação, que é uma estimativa de como anda o mercado imobiliário dos EUA. Habitação é um componente importante da economia dos EUA e, assim, se aumentar o índice de preços da habitação, isso geralmente irá criar um ambiente de alta (Touro) para os investidores. Se o índice cair, pode criar um ambiente de baixa (Urso). Como negociante fundamentalista, você terá feito a sua análise antes do lançamento deste relatório para que tenha sua posição aberta quando a notícia for divulgada e, dessa forma, lucre com a volatilidade que ela cria.',
            'Análise Técnica:  Para procurar oportunidades de negociações, os traders que usam Análise Técnica vão investir tempo analisando gráficos com a ajuda de indicadores e ferramentas. Exemplo:  Um negociante está monitorando o indicador de média móvel em dois períodos de tempo diferentes. Quando o indicador de longo prazo começa a cair abaixo do indicador de curto prazo, isso pode sinalizar que o mercado está perdendo força e pode começar a seguir na direção oposta. Isso incentivaria a vender ou comprar, dependendo da posição que está sendo realizada.',
            'Análise Sentimental:  Os investidores que usam essa forma de análise são mais raros do que os dois anteriores. Isso ocorre porque a Análise Sentimental é difícil e muitas vezes incompreendida. Exemplo: Existem ferramentas que dão aos negociantes uma visão coletiva do que todos os traders do mundo sentem sobre determinados pares, baseada nas operações deles. Determinados padrões e comportamentos desses indicadores darão conclusões sobre o que pode estar acontecendo no mercado para outras pessoas – que pode então ser uma oportunidade para negociar com fins lucrativos. Não existe um bom exemplo de introdução da Análise Sentimental, então é melhor deixar de lado por enquanto e abordá-la no futuro, em um artigo separado.'
        ]
      },
      {
        title: {
          name:'Corretoras & Tipos De Corretoras',
          parent: true
        },
        paragraphs: [
            'Nenhuma corretora de Forex foi criada de forma igual. Existem diferentes modelos de negócio para operar com uma corretora de Forex. Algumas delas apostam contra a sua própria base de clientes como forma de oferecer liquidez nos pares que desejam negociar instantaneamente mas, ao fazê-lo, tomam o outro lado da operação. Aqui estão algumas breves explicações:',
            'Market Makers ou Mesa de Negociação: Essas são as corretoras que tomam a contraparte da sua operação. Elas geralmente são identificadas por exigir depósitos mínimos mais altos e têm spreads mais amplos.',
            'Sem Mesa de Negociação:  Existem dois diferentes tipos de corretoras na categoria Sem Mesa de Negociação: corretoras ECN e corretoras ECN/STP. As corretoras ECN oferecem aos negociantes uma ligação direta com o mercado interbancário, o que significa que a corretora não se envolve nas operações. A corretora, nesse caso, não tem conflito de interesses, mas significa que, se não houver liquidez, o negociante deve esperar uma movimentação nos preços dos pares.',
            'As corretoras ECN/STP também obtêm as suas taxas diretamente do mercado interbancário mas, no caso de não haver liquidez, a corretora é capaz de oferecer liquidez ao negociante, tornando-se numa Mesa de Negociação e, por fim, tomando a contraparte da operação. Isso significa que pode haver um conflito de interesse da corretora, mas não na medida esperada de uma corretora puramente Mesa de Negociação.'
        ]
      },
      {
        title: {
          name:'Como Abrir A Sua Primeira Operação',
          parent: true
        },
        paragraphs: [
            'Agora que temos um bom entendimento do que é Forex e como o mercado Forex funciona, podemos pensar em abrir a primeira operação.',
            '1. Escolha o par de moedas que deseja negociar',
            'Exemplo:  Vamos negociar o EUR/GBP porque acreditamos que o valor do euro vai aumentar em relação à libra esterlina, depois que o Primeiro-Ministro Britânico se reunir com o Governo Europeu para discutir as condições do Brexit. Há rumores de que os europeus não vão gostar da proposta britânica, por isso queremos negociar essa volatilidade.',
            '2. Selecione o valor que deseja investir',
            'Mantendo o nosso exemplo, optamos por investir 150 euros na operação e decidimos usar alavancagem de 1:100. Isso significa que estaremos usando os nossos 150 Euros para comprar 15.000 euros de investimento. (Calculamos isso por capital de investimento vs alavancagem).',
            '3. Escolha a direção em que você acredita que o mercado vai se mover',
            'Como discutido no passo 1, acreditamos que o euro se tornará mais valioso contra a libra, então vamos Long e comprá-lo, sabendo que quando o valor do GBP cair, sairemos da operação com um número maior de GBP do que começamos.',
            '4. Feche o negócio quando achar que está na hora',
            'Abrimos a operação quando a taxa do EUR/GBP era de 0,8750 e, assim como esperamos, o euro ganha contra a libra. Nós saímos da operação a uma taxa de 0,8900. Esta é uma alteração de 0.015 (ou 15 PIPs). Se tomarmos 0,015 do nosso investimento original de 15.000 euros, teremos um lucro de 225 euros.',
        ]
      },
      {
        title: {
          name:'Conclusão',
          parent: true
        },
        paragraphs: [
            'Forex trading é a negociação de pares de moedas em que o negociante não se apropria do ativo, mas sim especula sobre o valor futuro das moedas. Porque a negociação Forex é especulativa, há risco envolvido e negociar em qualquer CFD é arriscado. Negociantes de Forex estão sempre trabalhando para controlar o risco a que eles mesmos se expõem, e quanto maior for a razão entre ganhos e perdas, mais lucro um negociante de Forex fará.'
        ]
      },
    ]
  })
}

</script>

<style scoped>

</style>