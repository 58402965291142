<template>
  <div class="mt-8 px-5">
    <!--<v-card class="grey darken-4 mb-10">
      <div class="px-0 px-5">
        <v-row justify="center" no-gutters>
          <v-col cols="12" lg="8" >
            <v-row align="center" justify="center">
              <v-col>
                <v-img class="hidden-xs-only" src="../assets/images/brokers.png"></v-img>
                <v-img class="hidden-sm-and-up " src="../assets/images/brokers_mobile.png"></v-img>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-card>-->
    
    <div>
    <v-row justify="center">
      <v-col cols="12" lg="8">
        <h1 class="font-weight-medium hidden-lg-and-up" style="font-size: 5vw;">Ranking das Melhores Corretoras <span class="font-weight-bold white--text" style="background: rgb(255,255,255);
background: linear-gradient(180deg, rgba(255,255,255,0) 10%, rgba(241,104,22,1) 10%, rgba(241,104,22,1) 50%, rgba(241,104,22,1) 90%, rgba(255,255,255,0) 90%);">2023</span></h1>
      <h1 class="font-weight-medium hidden-md-and-down" style="font-size: 3.5vw; ;">Ranking das Melhores Corretoras <span class="font-weight-bold white--text" style="background: rgb(255,255,255);
background: linear-gradient(180deg, rgba(255,255,255,0) 10%, rgba(241,104,22,1) 10%, rgba(241,104,22,1) 50%, rgba(241,104,22,1) 90%, rgba(255,255,255,0) 90%);">2023</span></h1>
      </v-col>
    </v-row>
      <v-row justify="center">
        <v-col cols="12" lg="8">
          <broker-list/>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" lg="8">
          <sections
              :sections="sections"
          ></sections>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" lg="8">
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" lg="8">
          <broker-overview></broker-overview>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import BrokerList from "../components/brokers/BrokerList";
import BrokerOverview from "@/components/brokers/BrokerOverview";
import Sections from "@/components/ui/Sections"

export default {
  name: "Brokers",
  components: {BrokerOverview, BrokerList, Sections},
  data: () => ({
    sections: [
      {
        title: {
          name: '',
          parent: true
        },
        paragraphs: [
          'Essas corretoras são todas confiáveis, têm taxas baixas e fornecem excelente educação em negociação para novos traders. Para compilar esta lista das melhores corretoras do Brasil, testamos mais de 160 corretoras Forex disponíveis para clientes brasileiros. As corretoras Forex no Brasil não são obrigadas a ter autorização da Comissão de Valores Mobiliários do Brasil (CVM), mas todas essas são reguladas por pelo menos um dos melhores reguladores do mundo.',
          'Para testar uma corretora, verificamos se ela é regulada e certificamos que seus custos de negociação não são muito altos, pois custos de negociação mais baixos oferecem maior oportunidade de lucro. Também avaliamos a qualidade de sua seção educacional e análise de mercado para garantir que novos traders sejam bem atendidos.  Estas são as melhores plataformas de corretoras Forex no Brasil para 2023.'
        ]
      },
    ]
  }),
  head: {
    title: function () {
      return {
        inner: 'Ranking'
      }
    },
    link: [
      { rel: 'canonical', h: 'https://melhorescorretorasdeforex.com/', id: 'canonical'},
    ]
  },
}
</script>

<style scoped>

</style>