<template>
  <div>
    <template >
      <v-sheet
          elevation="1"
          class="hidden-md-and-down px-3 py-1 rounded">
        <template v-for="(section, sIndex) of sections">
          <div v-if="section.title.parent" :key="sIndex">
            <div
                v-ripple
                class="section-topic my-2 rounded"
                @click="$vuetify.goTo('#section'+sIndex)"
            >
              <h2 class="mx-2 text--secondary my-1">
                {{ section.title.name }}
              </h2>
            </div>
            <v-divider v-if="sIndex+1<sections.length"></v-divider>
          </div>
        </template>
      </v-sheet>
    </template>
    <template >
      <v-sheet
          class="mx-auto hidden-lg-and-up "
          color="background"
      >
        <v-slide-group
            :show-arrows="$vuetify.breakpoint.md || $vuetify.breakpoint.sm"
            center-active
        >
          <template v-for="(section, sIndex) of sections">
            <v-slide-item
                :key="sIndex"
                v-if="section.title.parent"
            >
              <v-btn
                  class="mx-2"
                  active-class="accent back--text"
                  depressed
                  rounded
                  @click="$vuetify.goTo('#section'+sIndex)"
              >
                {{ section.title.name }}
              </v-btn>
            </v-slide-item>
          </template>
        </v-slide-group>
      </v-sheet>
    </template>
  </div>
</template>

<script>
export default {
  name: "SectionsContent",
  props:{
    sections: Array
  }
}
</script>

<style>
.section-topic{
  min-height: 48px;
  display: flex;
  align-items: center;
  transition: background-color 200ms;
}
.section-topic:hover{
  cursor: pointer;
  background-color: rgba(120, 120, 120, 0.08);
}
.section-topic > h2 {
  font-weight: 500;
  font-size: 1rem;
}
</style>