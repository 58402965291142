<template>
  <v-app>
    <v-app-bar
        app
        elevation="0"
        color="background darken-1"
        height="80px"
        class="ma-0"
    >
        <v-row justify="center" no-gutters>
        <v-col cols="12" lg="8">
          <v-row dense>
            <v-col cols="auto">
              <div class="logo-clickable" @click="$router.push('/')">
                <v-img v-if="$vuetify.theme.dark" src="./assets/logo-branco.png" width="150px"></v-img>
                <v-img v-else src="./assets/logo.png" width="150px"></v-img>
              </div>
            </v-col>
            <v-col align-self="center">
              <v-row justify="end">
                <v-col class="text--primary hidden-sm-and-down" align-self="center">
                  <v-row justify="end">
                    <v-col cols="auto">
                      <v-btn to="/forexguide" text class="text--secondary text-capitalize" :autocapitalize="false">
                        Guia Completo Para Forex
                      </v-btn>
                    </v-col>
                    <v-col cols="auto">
                      <v-btn class="text--secondary text-capitalize" id="learn" text @click="expand.learn = !expand.learn">
                        Aprenda
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="auto" sm="auto" md="2" lg="auto" align-self="center">
                  <v-row>
                    <v-col transition="transition" align-self="center" cols="auto">
                      <v-btn class="text--secondary text-capitalize" icon
                             @click="() =>this.$vuetify.theme.dark=!this.$vuetify.theme.dark">
                        <v-icon v-if="this.$vuetify.theme.dark">light_mode</v-icon>
                        <v-icon v-else>dark_mode</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col align-self="center" cols="auto">
                      <v-app-bar-nav-icon class="text--primary hidden-md-and-up" @click="drawer = true"></v-app-bar-nav-icon>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-navigation-drawer
        v-model="drawer"
        fixed
        temporary
        right
    >
      <v-list>
        <router-link  class="text-decoration-none" :to="{path: '/forexguide'}">
          <v-list-item @click="drawer = false">
            Guia Completo Para Forex
          </v-list-item>
        </router-link>
        <v-list-group :value="false">
          <template v-slot:activator>
            <v-list-item-title>Aprenda</v-list-item-title>
          </template>

          <router-link  class="text-decoration-none" :to="{path: '/forextrading'}">
            <v-list-item @click="drawer = false" class="ml-2">
              O que é Forex Trading?
            </v-list-item>
          </router-link>
          <router-link  class="text-decoration-none" :to="{path: '/forexaccount'}">
            <v-list-item @click="drawer = false"  class="ml-2">
              Abrir uma Conta Forex no Brasil
            </v-list-item>
          </router-link>
        </v-list-group>
        <v-list-item @click="drawer = false; clickOpen('https://www.instagram.com/melhorescorretoras/')">
            Instagram
        </v-list-item>
        <v-list-item @click="() => {drawer = false; clickOpen('https://www.facebook.com/Melhores-Corretoras-de-Forex-105469651998581')}">
            Facebook
        </v-list-item>
        <v-list-item @click="drawer = false; clickOpen('mailto:melhorescorretoras@gmail.com')">
            E-mail
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main width="103vw">
      <div class="hidden-sm-and-down" style="position: fixed; z-index: 9999; margin-left: 33%; top:90px; width: 50%;" >
        <v-expand-transition >
          <v-card
              v-show="expand.about"
              v-click-outside="e => clickedOutside(e, 'about')"
          >
            <v-card-title>Sobre Nós</v-card-title>
            <v-row class="pa-5">
              <v-col>
                <nav-bar-list-item
                    v-on:closePanel="expand['about'] = false"
                    path="/aboutus"
                >
                  Sobre Nós
                </nav-bar-list-item>
              </v-col>
            </v-row>
          </v-card>
        </v-expand-transition>
        <v-expand-transition >
          <v-card
              v-show="expand.learn"
              v-click-outside="e => clickedOutside(e, 'learn')"
          >
            <v-card-title>Aprenda</v-card-title>
            <v-row class="pa-5">
              <v-col>
                <nav-bar-list-item
                    v-on:closePanel="expand['learn'] = false"
                    path="/forextrading"
                >
                  O que é Forex Trading?
                </nav-bar-list-item>
              </v-col>
              <v-col>
                <nav-bar-list-item
                    v-on:closePanel="expand['learn'] = false"
                    path="/forexaccount"
                >
                  Abrir uma Conta Forex no Brasil
                </nav-bar-list-item>
              </v-col>
            </v-row>
          </v-card>
        </v-expand-transition>
      </div>
      <component v-bind:is="component"></component>
      <go-to-top-btn v-show="topBtn"></go-to-top-btn>
    </v-main>
    <v-footer color="grey darken-4" class="white--text mt-10">
      <v-row class="py-10 mb-0" justify="center">
        <v-col cols="12" lg="8">
          <v-row justify="center">
            <v-col cols="10" lg="" align-self="center">
              <ul style="list-style: none;">
                <li><a class="white--text" style="text-decoration: none">Melhores Corretoras de Forex</a> &copy; 2023</li>
                <li>Contato: <a class="white--text" href="mailto:melhorescorretoras@gmail.com">melhorescorretoras@gmail.com</a></li>
              </ul>
            </v-col>
            <v-col cols="auto">
              <v-btn class="mr-3" v-bind="attrs" v-on="on"  icon href="https://www.facebook.com/Melhores-Corretoras-de-Forex-105469651998581" target="_blank" rel="noopener noreferrer">
                  <v-icon color="white" x-large >mdi-facebook</v-icon>
                </v-btn>
                <v-btn v-bind="attrs" v-on="on" icon href="https://www.instagram.com/melhorescorretoras/" target="_blank" rel="noopener noreferrer">
                  <v-icon color="white" x-large>mdi-instagram</v-icon>
                </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import Home from "./pages/Home";
import ForexGuide from "./pages/ForexGuide";
import Broker from "./pages/Broker";
import ForexTrading from "./pages/learn/Trading";
import ForexBroker from "./pages/learn/Broker";
import ForexAccount from "./pages/learn/Account";
import AboutUs from "@/pages/aboutUs/AboutUs";
import NavBarListItem from "@/components/ui/NavBarListItem";
import GoToTopBtn from "@/components/ui/GoToTopBtn";


export default {
  name: 'App',
  components: {GoToTopBtn, NavBarListItem},
  methods: {
    clickedOutside(event, area){
      if(event.target.id !== area && event.target.parentNode.id !== area) this.expand[area] = false
    },

    loadComponent(name){
      this.component = this.routes[name]
    },

    clickOpen(link){
      window.open(link, '_blank');
    },

    showTopBtn(e){
      if(e.target.documentElement.scrollTop > 600){
        this.topBtn = true
      }
      else if (e.target.documentElement.scrollTop < 500){
        this.topBtn = false
      }
    }
  },

  data: () => ({
    component: Home,
    routes:{
      'home': Home,
      'forexguide': ForexGuide,
      'broker': Broker,
      'forextrading': ForexTrading,
      'forexbroker': ForexBroker,
      'forexaccount': ForexAccount,
      'aboutus': AboutUs,
    },
    darkmode: false,
    drawer: false,
    topBtn: false,
    expand: {
      about: false,
      learn: false
    }
  }),
  watch: {
    $route(to){
      this.loadComponent(to.name)
    }
  },
  mounted() {
    this.loadComponent(this.$route.name)
    window.addEventListener('scroll', this.showTopBtn)
  },
};
</script>
<style>
html { overflow-y: auto }

.logo-clickable
{
  cursor: pointer;
}
</style>