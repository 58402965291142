<template>
  <div>
    <template v-for="(info,index) in brokerInfo">
      <div :key="info.label" class="my-2">
        <v-row dense>
          <v-col>{{ info.label }}</v-col>
          <v-col class="text-right">
            <div v-if="info.value === ''">-</div>
            <div v-else-if="info.type==='check'">
              <span>
                <v-icon v-if="info.value">mdi-check</v-icon>
                <v-icon v-else>mdi-close</v-icon>
              </span>
            </div>
            <div v-else-if="info.type==='star'">
              <star-rating :rate="info.value*2" size="15"></star-rating>
            </div>
            <div class="text-body-1" v-else-if="info.prefix">
              {{ info.prefix }} {{ info.value }}
            </div>
            <div class="text-body-1" v-else>
              {{ info.value }}
            </div>
          </v-col>
        </v-row>
      </div>
      <v-divider :key="index"></v-divider>
    </template>
  </div>
</template>

<script>
import StarRating from "@/components/ui/StarRating";
export default {
  name:"List",
  components: {StarRating},
  props:{
    brokerInfo: Object
  }
}
</script>

<style scoped></style>