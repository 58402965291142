<template>
  <div>
    <title-card>Escolha uma corretora Forex</title-card>
    <essay
      :sections="sections"
    ></essay>
  </div>
</template>

<script>
import TitleCard from "@/components/layout/TitleCard";
import Essay from "@/components/layout/Essay";
export default {
  name: "Broker",
  components: {Essay, TitleCard},
  data: () => ({
    sections: [
      {
        title: {
          name: '',
          parent: false
        },
        paragraphs: [
          'A negociação com Forex é o maior mercado de varejo do mundo e a proliferação de corretoras on-line nos últimos anos tornou-o mais acessível do que nunca. Este breve guia para quem está começando explicará como:',
          'É importante lembrar que a negociação Forex não é um esquema para ficar rico rapidamente — é, sim, uma estratégia de investimento a longo prazo e, se você quiser ser bem-sucedido, terá que ser cuidadoso e inteligente, especialmente se estiver começando pequeno.',
          'Quando terminar de ler este artigo, você terá escolhido uma boa corretora, aberto uma Conta Demo, começado a aprender mais sobre esse assunto e estará pronto para abrir uma conta de negociação real. Com um pouco de aprendizado, cautela e foco, qualquer um pode aprender a negociar com sucesso. Veja como fazer isso.',
        ]
      },
      {
        title: {
          name: 'Escolha uma corretora certa para você',
          parent: true
        },
        paragraphs: [
          'A primeira coisa que você deve procurar em uma corretora é se e como ela é regulada. Existem várias agências de regulação em todo o mundo, algumas delas bem respeitadas e outras nem tanto. As melhores corretoras são reguladas por pelo menos um dos três grandes: a Autoridade de Conduta Financeira do Reino Unido (FCA), a Comissão de Valores Mobiliários do Chipre (CySEC) e a Comissão Australiana de Valores Mobiliários e Investimentos (ASIC).',
          'No Brasil, os mercados financeiros são rigidamente regulados pela Comissão de Valores Mobiliários (CVM). Embora negociar Forex CFDs não seja ilegal, nenhuma corretora Forex está atualmente licenciada para oferecer serviços no Brasil. Isso significa que não há corretoras de Forex locais no Brasil e você terá que usar uma corretora offshore.',
          'A CVM emite avisos sobre corretoras Forex suspeitas que operam no país mas, como eles não licenciam corretoras Forex, não há listagem de corretoras seguras. Por isso mesmo, é importante que você conduza sua própria pesquisa sobre o status regulatório de uma corretora.',
          'Outros órgãos reguladores que você frequentemente verá são a Autoridade de Serviços Financeiros das Seychelles (FSA), a Autoridade Monetária das Ilhas Cayman (CIMA) e a Comissão de Serviços Financeiros das Ilhas Virgens Britânicas (BVI FSC) e, embora esses reguladores sejam geralmente bem vistos, elas não são tão apreciados quanto a FCA, CySEC ou ASIC.',
          'Certifique-se de escolher uma corretora que seja regulada por pelo menos um dos três grandes — ou, se você não fizer isso, tenha a certeza de que tem uma boa razão para isso.',
          'Há muitos corretoras para escolher e nem todas elas vão ser a escolha certa para você. Felizmente, temos revisões honestas e detalhadas de todas as melhores corretoras do Brasil aqui para você ler. É importante encontrar uma que corresponda às suas necessidades. Por isso, em nossas análises nós procuramos detectar:',
          'É muito importante conferir nosso guia sobre as melhores corretoras de Forex para iniciantes. Não apenas listamos nossas corretoras favoritas para novos traders, como também detalhamos o que você deve observar como um trader iniciante ao procurar uma corretora. Nossas revisões são totalmente imparciais e só trabalhamos com entidades reguladas para que você possa ter a certeza de que, qualquer que seja sua escolha, seu dinheiro estará seguro.',
          'Outras coisas importantes a considerar ao escolher uma corretora são se sua plataforma de negociação é denominada em Rand, se ela oferece suporte ao cliente em sua língua materna, qual é o depósito mínimo e que tipo de suporte técnico ela coloca à sua disposição.',
        ]
      },
      {
        title: {
          name: 'Abra uma Conta Demo',
          parent: true
        },
        paragraphs: [
          'Depois de ter escolhido uma corretora bem regulada, com o suporte ao cliente correto e as condições de negociação necessárias, é hora de abrir uma conta de demonstração, ou Conta Demo. Se você estiver com problemas para escolher uma corretora com uma boa Conta Demo, confira nosso artigo sobre as corretoras de Forex com as melhores contas de demonstração.',
          'Contas Demo não são apenas para jogar – elas devem ser usadas como uma ferramenta educativa séria. A maioria dos corretores oferece contas de demonstração ilimitadas com 100.000 dólares (ou equivalente) de dinheiro virtual para começar. Isso deve ser mais do que suficiente para você aprender como a plataforma do corretor opera e os fundamentos da negociação. Mantenha sua conta de demonstração aberta e use-a com frequência, mesmo depois de abrir uma conta ativa, pois ela é um ótimo test-drive para novas estratégias ou pares de moedas desconhecidos.',
        ]
      },
      {
        title: {
          name: 'Eduque-se',
          parent: true
        },
        paragraphs: [
          'Isso é da maior importância. Depois de escolher um corretor e abrir uma conta de demonstração, você precisa começar a se instruir. Leia / assista o máximo que puder; leia o nosso guia essencial para o comércio Forex – é um ótimo lugar para começar.',
          'Se você seguiu o nosso guia para os melhores corretores de Forex para iniciantes, você terá escolhido um com uma excelente seção de educação: aproveite isso. Muitos terão tutoriais em vídeo e até mesmo seminários on-line.',
          'Costumo dizer que você nunca pode ser muito educado quando se trata de negociação Forex; tudo que você aprende só o ajudará a negociar melhor e mais lucrativamente. Aprenda a diferença entre análise fundamental e análise técnica, aprenda como gerenciar seu risco com eficiência, aprenda como as ferramentas de gráficos funcionam em sua plataforma, aprenda como a cópia e os algos podem torná-lo mais bem sucedido, saiba como o mercado reage aos lançamentos de dados do governo. Se você for um negociante bem-sucedido, nunca deixará de aprender, por isso faça da educação um hábito.'
        ]
      },
      {
        title: {
          name: 'Decida sua estratégia de negociação',
          parent: true
        },
        paragraphs: [
          'Existem muitas estratégias de negociação Forex, e não existe a melhor estratégia – é muito dependente da sua personalidade e estilo de vida. Leia nosso artigo sobre como colocar seu primeiro comércio Forex para uma boa compreensão dos elementos básicos de uma estratégia bem-sucedida.',
          'As duas principais estratégias são:',
          'Escalpelamento – esta é a prática de entrar e sair do mercado algumas vezes por dia e obter pequenos lucros de pequenos movimentos do mercado. Isso requer foco constante e manter um olhar atento sobre os lançamentos de dados / eventos de notícias que afetarão o mercado. Essa estratégia é desgastante e demorada, mas funciona. Mesmo que os lucros individuais não sejam enormes, eles se somam com o tempo.',
          'Negociação posicional – esta é a prática de manter uma posição aberta a longo prazo – geralmente semanas a fio. Isso consome menos tempo do que o escalpelamento, mas requer que você tenha uma boa ideia de como o mercado irá se mover por um longo período de tempo. Isso também pode ser uma forma particularmente estressante de negociação, pois você será tentado a fechar sua negociação quando o mercado se mover contra você no curto prazo. Se você conhece o mercado e está confiante em suas previsões, aprenderá a se manter firme.',
          'Existem muitas estratégias mais complexas com as quais você se deparará, mas eduque-se antes de tentar usá-las, elas exigem uma sólida compreensão do mercado e uma série de ferramentas analíticas que podem ser adicionadas à sua plataforma.',
          'Você deve sempre experimentar uma estratégia em uma conta de demonstração por algum tempo antes de fazer a transmissão ao vivo. Certifique-se de que ela seja bem-sucedida, pelo menos a maior parte do tempo, antes de começar a gastar seu próprio dinheiro.',
          'Lembre-se que o comércio Forex requer muita tentativa e erro. Para ter sucesso, você terá que tentar várias estratégias diferentes antes de encontrar a certa, ou combinação, que funcione para você.'
        ]
      },
      {
        title: {
          name: 'Decida quanto você pode perder',
          parent: true
        },
        paragraphs: [
          'Então, você tem um bom corretor, você tem uma boa compreensão do mercado e você tem uma estratégia de negociação que funcionou bem com sua conta demo. Agora você tem que decidir quanto você pode perder – e eu quero dizer isso da maneira mais gentil possível.',
          'O mercado Forex pode ser um lugar cruel e implacável para aqueles que entram nele sem o cuidado necessário. Nunca coloque mais do que você pode perder em sua conta de negociação e nunca negocie mais do que você deseja perder em uma única negociação. Certifique-se de que a negociação não será dolorosa para você e você terá muito menos estresse e muito mais diversão.',
          'Uma boa regra é nunca negociar mais de 5% de sua conta em um determinado dia e nunca negociar mais de 2% de sua conta em um único negócio.'
        ]
      },
      {
        title: {
          name: 'Abra sua conta ao vivo!',
          parent: true
        },
        paragraphs: [
          'Quando você abre uma conta de negociação ao vivo, você será solicitado para uma série de coisas:',
          'Prova de identificação – verifique se você possui um documento de identidade válido com foto emitido pelo governo, como um passaporte ou carteira de motorista.',
          'Você também será solicitado a preencher um requerimento – isso pode incluir questões de solução de problemas e outros testes para determinar sua capacidade matemática e conhecimento financeiro e pode afetar a quantidade de alavancagem oferecida. Depois de concluído, pode levar até 24 horas para que sua inscrição seja aprovada.',
          'Depois de ter a aprovação do seu corretor, você pode ir em frente e fazer sua primeira negociação. Parabéns e boa sorte! Bem-vindo ao mercado comercial mais emocionante do mundo.'
        ]
      }
    ]
  })
}
</script>

<style scoped>

</style>