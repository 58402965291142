<template>
  <div class="click-block" @click="clickOpenAcc(broker.link)">
  <v-row class="broker-list-block pl-0 pr-2" justify="center" dense no-gutters>
    <v-col cols="auto" order="1" align-self="center">
      <v-row class="pr-2" dense no-gutters>
        <v-col cols="auto" align-self="center">
          <v-card elevation="0" light class="text-center py-2" style="border-radius: 6px;">
            <v-row dense no-gutters class="ma-0" justify="center">
              <a class="centered green white--text">Regulamentado</a>
            </v-row>
            <v-img class="hidden-sm-and-up" :src="`/broker-avatars/${broker.label}.webp`" style="max-width: 110px;"/>
            <v-img class="hidden-xs-only" :src="`/broker-avatars/${broker.label}.webp`" style="max-width: 180px;"/>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col order="2" align-self="center">
      <v-row dense>
        <v-col align-self="center">
          <v-row dense no-gutters>
            <v-col cols="12">
              <span class="font-weight-bold">
                {{ broker.name }}
              </span>
            </v-col>
            <v-col>
              <!--<a class="grey--text"><flag class="mx-1" :iso="broker.regulatorsFlag[0]" :title="' '"/>|</a>-->
              <a class="grey--text broker-description" style="font-size: 0.9rem;">
                {{ broker.description }}
              </a>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col class="hidden-sm-and-up mr-2" cols="auto" order="2" align-self="center">
      <v-row justify="center" dense no-gutters>
        <v-col cols="" lg="auto">
          <v-row justify="center" dense no-gutters>
            <v-col cols="auto">
              <a class="font-weight-bold tertiary--text" style="font-size: 0.6rem; line-height: 0.5;">
                Pontuação
              </a>
            </v-col>
          </v-row>
          <v-row justify="center" dense no-gutters style="margin-top: -7px; margin-bottom: -14px;">
            <v-col cols="auto">
              <a class="rate-text-xs tertiary--text">
                {{ broker.rate }}
              </a>
            </v-col>
          </v-row>
          <v-row dense no-gutters justify="center">
            <v-col cols="auto">
              <star-rating :rate="broker.rate" size="8"></star-rating>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col class="hidden-xs-only mr-4" cols="auto" order="2" align-self="center">
      <v-row justify="center" dense no-gutters>
        <v-col cols="" lg="auto">
          <v-row justify="center" dense no-gutters>
            <v-col cols="auto" style="margin-top:">
              <span class="text-body-1 font-weight-bold" style="color: #f16816; font-size: 8pt !important">
                Pontuação
              </span>
            </v-col>
          </v-row>
          <v-row justify="center" dense no-gutters style="margin-top: -8px; margin-bottom: -15px;">
            <v-col cols="auto">
              <span class="rate-text tertiary--text">
                {{ broker.rate }}
              </span>
            </v-col>
          </v-row>
          <v-row justify="center" dense no-gutters>
            <v-col cols="auto">
              <star-rating class="stars" :rate="broker.rate" size="9"></star-rating>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col class="hidden-xs-only" cols="auto" order="3" align-self="center">
      <v-avatar size="20" :color="rank > 3 ? '#e0e0e0' : rank === 1 ? '#ffc869' : rank === 2 ? '#8597C1' : '#A38566'">
        <span class="blb-rank back--text">{{ rank }}</span>
      </v-avatar>
    </v-col>
    <v-col class="hidden-sm-and-up" cols="auto" order="3" align-self="center">
      <v-avatar size="20" :color="rank > 3 ? '#e0e0e0' : rank === 1 ? '#ffc869' : rank === 2 ? '#8597C1' : '#A38566'">
        <span class="blb-rank back--text">{{ rank }}</span>
      </v-avatar>
    </v-col>
  </v-row>
</div>
</template>

<script>
import StarRating from "@/components/ui/StarRating";
export default {
  name: "BrokerListBlock",
  components: {StarRating},
  props: {
    broker: Object,
    rank: Number,
  },
  methods: {
    clickOpenAcc(link){
      window.open(link, '_blank');
    },
  },
}
</script>

<style scoped>

.rate-text{
  font-weight: bolder;
  font-size: 16pt;
  line-height: 0.5;
}

.rate-text-xs{
  font-weight: bolder;
  font-size: 14pt;
  line-height: 0.8;
}
.click-block{
  text-decoration: none;
  cursor: pointer;
}
.broker-list-block{
  border-radius: 6px;
}
.broker-list-block:hover{
  background-color: rgba(241,104,22,0.1)
}
.blb-rank{
  font-size: 10pt;
  font-weight: bolder;
}

.broker-description {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.container {
  position: relative;
  text-align: center;
  color: white;
}

.centered {
  z-index: 1;
  position: absolute;
  width: 100%;
  line-height: 1.2;
  font-size: 0.8rem;
  top: 0;
  border-radius: 6px 6px 0 0;
}
</style>