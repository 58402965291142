<template>
  <div>
    <div class="px-5">
      <v-row justify="center">
        <v-col cols="12" lg="8">
          <v-row>
            <v-col cols="12" lg="4" xl="3" >
              <div class="sections-content">
                <sections-content
                    :sections="sections"
                ></sections-content>
              </div>
            </v-col>
            <v-col>
              <sections
                  :sections="sections"
              ></sections>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Sections from "@/components/ui/Sections";
import SectionsContent from "@/components/ui/SectionsContent";
export default {
  name: "Essay",
  components: {SectionsContent, Sections},
  props: {
    sections: Array
  }
}
</script>

<style scoped>
.sections-content{
  position: -webkit-sticky;
  position:sticky;
  top: 100px;
}
</style>