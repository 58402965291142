<template>
  <div>
    <v-row justify="center">
      <v-col align-self="center" cols="12" md="3" lg="3" xl="2">
        <v-row justify="center">
          <v-col cols="auto" class="align-center">
            <v-card elevation="0" light>
              <img class="pa-1" :src="`/broker-avatars/${brokerLabel}.webp`" width="100%" style="max-width: 170px; vertical-align: middle" alt=""/>
            </v-card>
          </v-col>
        </v-row>
        <v-row >
          <v-col class="text-center">
            <v-row>
              <v-col>
                <div class="text-h5 font-weight-bold">Pontuação Geral</div>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <div class="text-h4">
                  <star-rating :rate="brokerRate" size="32"></star-rating>
                  <a class="rate-text">{{ brokerRate }}</a>
                  <span class="font-weight-thin">
                  / 10
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-divider vertical class="hidden-sm-and-down" ></v-divider>
      <v-col align-self="center" cols="12" md="9" lg="9" xl="8">
        <list :broker-info="brokerMainInfo"></list>
      </v-col>
      <v-divider vertical class="lg hidden-lg-and-down"></v-divider>
      <v-col class="hidden-xl-only hidden-sm-and-down">
        <v-divider></v-divider>
      </v-col>
      <v-col class="text-center" align-self="center" cols="12" md="12" lg="12" xl="2">
        <v-btn color="tertiary white--text" @click="clickOpenAcc(brokerLink)">ABRA SUA CONTA</v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
  import List from "@/components/broker/ui/List";
  import StarRating from "@/components/ui/StarRating";

  export default {
    name:"BrokerPanel",
    components: {StarRating, List},
    props:{
      brokerLabel: String,
      brokerRate: Number,
      brokerRank: Number,
      brokerLink: String,
      brokerMainInfo: Object
    },
    methods: {
      clickOpenAcc(link){
        window.open(link, '_blank');
      },
    }
  }
</script>
<style scoped>
.rate-text{
  font-weight: bolder;
  font-size: 2.4rem;
  line-height: 0.8;
}
</style>